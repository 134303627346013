import React from "react";
import { useSearchParams } from "react-router-dom";
import { Plan } from './PlanCard';

export const PlanPage = ({type = "input"}) => {

  const [searchParams, setSearchParams] = useSearchParams()
  const planDetails = Object.fromEntries(searchParams.entries())
  const inputPlan = {planType: type, planDetails}

  return (
    <div className="standalone">
      <Plan 
        trip={{...inputPlan, planUpdater: setSearchParams }} 
        cardStyle={{height: '100svh', colorScheme: 'light', background: 'var(--primary-background)'}} 
        standalone={true}
      />
    </div>
  );
}