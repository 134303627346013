import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import '@ionic/react/css/core.css';
import '@ionic/react/css/palettes/dark.system.css';
import { setupIonicReact, IonApp } from '@ionic/react';
import App from './App';
import { PlanPage } from './PlanPage';
import { HomePage } from './HomePage';
import { LandingPage } from './LandingPage';
import * as Test from './_local/Plan';
import reportWebVitals from './reportWebVitals'

import { MapContainer } from './MapContainer'; // Ensure the correct path

setupIonicReact();

//commenting out as part of react 16.14 ->18 ugprade
//import registerServiceWorker from './registerServiceWorker';

//ReactDOM.render(<App />, document.getElementById('root'));
//registerServiceWorker();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <IonApp>
    <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/pwa" element={<HomePage title="Pins" />} />
          <Route path="/list" element={<PlanPage type="list"/>} />
          <Route path="/expenses" element={<PlanPage type="expenses"/>} />
          <Route path="/search" element={<PlanPage />} />
          <Route path="/res" element={<PlanPage type="res"/>} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/l/:listTitle" element={<HomePage/>} />
          <Route path="/app" element={<HomePage title="Wallet" />} />
          <Route path="/test" element={<Test.Plan />} />
          <Route path="/map" element={<MapContainer locations={Test.locationsMock} />} />
          {/*<Route path="invoices" element={<Invoices />} />*/}
        </Routes>
      </BrowserRouter>
  </IonApp>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();