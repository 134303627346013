import React, { useEffect, useState } from "react";
import Itinerary from "@kiwicom/orbit-components/lib/Itinerary";
import * as Segments from './Segments'
import {hydratePlan} from './PlansManager'

export const fetchSearch = ( url, statusSetter, resultsSetter, options={} ) => {
  console.log(url, statusSetter, resultsSetter, options)
  statusSetter('Loading');
  fetch(url, options)
    .then((response) => {
        return response.json()
          .then((json) => {
            if (response.ok) {
              return Promise.resolve(json)
            }
            return Promise.reject(json)
          })
      })
    .then(resultsSetter)
    .then(()=>statusSetter('Success'))
    .catch((ex) => {
      const errorMsg = `error fetching: ${url}, ${ex.error || ex.message}`
      console.log(errorMsg, ex)
      statusSetter(errorMsg)
    });
}

export const fetchPlansList = ( method, payload, listName='Pins',
  setList = (list) => console.log(list),
  setStatus = (status) => console.log(status) //window.alert(status)
 ) => {
  //console.log(method, payload, listName,setList,setStatus)
  const url = `/plans?listId=${listName}`
  //should come from outside

  const options = {
    method: method, //GET, POST, PUT, DEL
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    ...(payload && {body: JSON.stringify(payload)})
  }
  fetchSearch(url, setStatus, (data) => setList(data.plans.toReversed()), options)
}

/*
1. insert pin if not exists
2. add to pin_list for pinning user
3. remove from pin_list if untoggled
*/

export const DetailsButton = ( {description, defaultOpen} ) => (
  <details open={defaultOpen}>
  <summary style={{ textAlign: 'right', color: 'var(--input-color)'}}>Info&nbsp;&nbsp;</summary>
  <p style={{padding: '0px 12px', textAlign: 'center'}}>
    {description }
  </p>
  </details>
)

function toggleItemInArray(array, item) {
  return array.includes(item) ? array.filter(i => i !== item) : [...array, item]
}

const ItineraryListSegment = (props) => {
  const {type, ...childProps} = props;
  const Segment = Segments.segmentTypes[type];
  return <Segment {...childProps}/>; 
}

export const ItineraryList = ( { items, type, updatePins, pind_ids = [] } ) => {

  const styles = {
    //background: 'white', //remove for card look
    minHeight: '280px',
    width: '100%', // otherwise width is based on biggest inner element (nested link!)
    //margin: '1px auto',//overflow on right for PR... auto bad?
    overflowY: 'auto',
    overflowX: 'hidden',
    //lineHeight: '30px'
  }

  function savePin(segment) {
    updatePins(toggleItemInArray(pind_ids, segment.id))
    const payload = hydratePlan(type, segment)
    //console.log(payload)
    fetchPlansList('POST', payload)
  }

  return (
    <div style={styles}>
    <Itinerary>
      {(items || [])
        .sort((item) => !pind_ids.includes(item.id)) // sort pind to top of list
        .map( (item, index) =>
          <ItineraryListSegment
            key={item.id || index}
            type={item.type || type} 
            item={item} 
            pind={pind_ids.includes(item.id)}
            //pinUpdater={updatePins && (() => updatePins(toggleItemInArray(pind_ids, item.id)))}
            pinUpdater={updatePins && (() => savePin(item))}
          />
          )
      }
    </Itinerary>
    </div>
  );
}
export default ItineraryList;

export const ItineraryBody = ( {plan} ) => {
  const { planUpdater: inputsUpdater, planType, link, ...planProps } = plan;
  const searchParams = new URLSearchParams();
  searchParams.append("sheet_id",  plan?.planDetails?.sheetId);

  const endpoint = (planType == 'expenses' ? 'sheetsexpenses' : 'sheetstrip')
  const url =  `/${endpoint}?${searchParams.toString()}`

  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);
  const segments = list.trip?.segments || []
  const shouldFetch = (segments.length == 0) && !!inputsUpdater; //isCardOpen

  useEffect(()=>{

    if ( plan?.segments?.length > 0 ) {
      setList({'trip': plan})
      return setStatus('Success')
    }
    if (!plan?.planDetails?.sheetId) {
      return setStatus('Link provided is not valid')
    }
    
    shouldFetch && fetchSearch(url, setStatus, setList)

  }, [url, shouldFetch]);

  return (
    <>
    <DetailsButton 
      description={
        status !== 'Success' 
        ? status 
        : <Segments.HostNameLink url={link || `${window.location.origin}${url}`}/>
      }
      defaultOpen={segments.length == 0}
    />
    <ItineraryList items={segments}/>
    </>
  );
}

export const PindPlans = ( {plans, searchValue} ) => {
  const [status, setStatus] = useState('');
  const [list, setList] = useState([]);

  useEffect(()=>{
    fetchPlansList('GET', null, 'Pins', setList, setStatus)
    }, []);
  console.log(list) //filter by type?

  return (
    <div style={{colorScheme: 'light dark' //, backgroundColor: 'light-dark(white, #999999)'

    }}>
    <ItineraryList 
      items={list
        .map(seg => ({...seg.planDetails, 'type': seg.planType}))
        .filter((s) => new RegExp(`.*${searchValue}.*`, 'i').test(s.name) )
      }
      //pind_ids={plans.flatMap(p => p.planDetails.pind_ids ? p.planDetails.pind_ids.split(',') : [])}
      //updatePins={(pind_arr) => inputsUpdater({...item, pind_ids: pind_arr.join(',')})}
    />
    </div>
)}