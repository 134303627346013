import { Map, Marker, FeatureVisibility } from 'mapkit-react';
import React, { useEffect, useRef } from 'react';

function CustomCalloutContent({ title, subtitle }) {
  return (
    <div className="custom-annotation-content" 
    style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    }}
    >
      <h2 style={{fontSize: '20px', fontWeight: '600', margin: '0'}}>{title ?? ''}</h2>
      <p style={{margin: 0, fontSize: '12px'}}>{subtitle ?? ''}</p>
    </div>
  );
}

function CustomCalloutLeftAccessory({ src }) {
  return (
    <div className="custom-annotation-image" style={{height: '40px', width: '40px'}}>
      <img style={{width: '100%', height: '100%', borderRadius: '8px'}} src={src} alt="" />
    </div>
  );
}

function CustomCalloutRightAccessory({ url }) {
  return (
    <div className="custom-annotation-info" style={{padding: '10px'}}>
      <a href={url} target="_blank" rel="noreferrer" aria-label="Learn more">
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 20 20"
          stroke="#333"
          strokeWidth="2"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="10" cy="10" r="9" />
          <line x1="10" y1="9" x2="10" y2="15" />
          <circle cx="10" cy="5" r="0.1" />
        </svg>
      </a>
    </div>
  );
}

const CustomCalloutProps = ({ name, subtitle, imgSrc, url }) => {

  return {
    calloutContent: <CustomCalloutContent title={name} subtitle={subtitle} />,
    calloutLeftAccessory: <CustomCalloutLeftAccessory src={imgSrc} />,
    calloutRightAccessory: <CustomCalloutRightAccessory url={url} />,
  }
}

const calcRegionFromList = (locationsArr) => {
  const latitudes = locationsArr.map(loc => loc.latitude);
  const longitudes = locationsArr.map(loc => loc.longitude);

  const minLat = Math.min(...latitudes);
  const maxLat = Math.max(...latitudes);
  const minLong = Math.min(...longitudes);
  const maxLong = Math.max(...longitudes);

  return {
    centerLatitude: (minLat + maxLat) / 2,
    centerLongitude: (minLong + maxLong) / 2,
    latitudeDelta: (maxLat - minLat) * 1.5 || 0.01,
    longitudeDelta: (maxLong - minLong) * 1.5 || 0.01,
  };
};

export const MapContainer = ({ locations }) => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current && locations.length > 0) {
      const newRegion = calcRegionFromList(locations.slice(0, 2));
      // var coordinate = new mapkit.Coordinate(newRegion.centerLatitude, newRegion.centerLongitude);
      // var span = new mapkit.CoordinateSpan(newRegion.latitudeDelta, newRegion.longitudeDelta);
      // var region = new mapkit.CoordinateRegion(coordinate, span);
      // console.log(newRegion, region)
      // mapRef.current.setRegionAnimated(region);

      mapRef.current.setRegionAnimated(
        new mapkit.CoordinateRegion(
          new mapkit.Coordinate(newRegion.centerLatitude, newRegion.centerLongitude),
          new mapkit.CoordinateSpan(newRegion.latitudeDelta, newRegion.longitudeDelta),
        ),
      );
    }
  }, [locations]);

  return (
    <Map 
      ref={mapRef}
      token={process.env.REACT_APP_MAPKIT_TOKEN}
      initialRegion={calcRegionFromList(locations.slice(0, 2))}
    > 
      {locations.map((marker) => (
        <Marker
          {...marker}
          key={marker.id}
          subtitleVisibility={FeatureVisibility.Visible}

          calloutEnabled 
          {...CustomCalloutProps(marker)}
        />
      ))}
    </Map>
  );
};