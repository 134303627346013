import { useEffect, useState, useRef } from "react";
import { useParams } from 'react-router-dom';
import { ColorButton, MultiOptionsButton, DeleteButton } from './components/ImgButton';
import { PlanAdder, PlansProfile } from './PlansManager';
//import { IonPage } from '@ionic/react';
import { PlanCard, usePlanState } from './PlanCard';
import { BlurBar } from './components/BlurBar';
import Card from './cardStack/Card';
import CardStack from './cardStack/CardStack';
import TestPlans from './plans.json';

import {fetchPlansList} from './ItineraryList'

function savePin(pinId, listId) {
  return fetch('/pins', {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ pinId, listId })
  }).then(response => response.json());
}

function savePins(plans) {
  return Promise.all(
    console.log(plans)
    //plans.map(element => setTimeout(savePin(element.planId, 1),500))
  );
}

function getBodyHeight(toolbarHeight) {
  // bodyHeight = totalHeight - toolbarHeight w/padding - blurbarHeight (if it's visible)
  const totalHeightInPixels = window.innerHeight;
  const blurbarHeight = toolbarHeight * (window.navigator.standalone | 0)
  return totalHeightInPixels - 2 * toolbarHeight - blurbarHeight
}

const PlanColorButton = ({diameter, plan, plansUpdater}) => {
  const [color, changeHandler] = usePlanState({...plan, planUpdater: plansUpdater(plan.planId)}, 'bgColor')
  return (
    <ColorButton diameter={diameter} {...{color, changeHandler}}/>
  )
}

export const HomePage = ({ title = 'Plans', toolbarHeight = 32 }) => {
  const parentRef = useRef(null);
  const {listTitle} = useParams();
  const [appTitle, setAppTitle] = useState(listTitle || title); //window.navigator.standalone ?
  const [status, setStatus] = useState('');
  const [plans, setPlans] = useState([]);
  const [selectedCardIdx, setSelectedCardIdx] = useState(-1);
  const [stackOptions, setStackOptions] = useState({
    'View Options': {
      "Mini Header": true,
      "Mono Theme": false,
    },
    'Plan Types': {
      "list": true,
      "search": true,
      "res": true,
    }
  });
  const [bodyHeightInPixels, setBodyHeightInPixels] = useState(getBodyHeight(toolbarHeight))
  // make body height responsive if mobile orientation changes or browser resizes
  if (window.navigator.standalone) {
    screen.orientation.addEventListener("change", () => setBodyHeightInPixels(getBodyHeight(toolbarHeight)))
  } else {
    window.onresize = () => setBodyHeightInPixels(getBodyHeight(toolbarHeight))
  }

  const fetchPlansDB = ( method, payload, listName=appTitle ) => {
    const url = `/plans?listId=${listName}`
    fetch(url, {
      method: method, //GET, POST, PUT, DEL
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      ...(payload && {body: JSON.stringify(payload)})
    })
    .then(response => response.json())
    //.then(resjson => savePins(resjson.plans))
    .then(resjson => {console.log(method,resjson); return resjson})
    .then(resjson => setPlans(resjson.plans.toReversed()))
    .then(()=>setStatus('Success'))
    .catch((ex) => {
      const errorMsg = `error fetching: ${url}, ${ex.error || ex.message}`
      console.log(errorMsg, ex)
      setStatus(errorMsg) //why does it keep stack and show previous newest plan on 500 then?
     });
  }

  const fetchPlansLocal = ( method, payload ) => {
    // https://stackoverflow.com/questions/2989284/what-is-the-max-size-of-localstorage-values
    if (method == 'POST') {
      const nextPlanId = 1 +  Math.max(...plans.map(obj => obj.planId), 0) 
      payload = {planId: nextPlanId, planName: `Plan ${nextPlanId}`, ...payload}

      const updPlans = [payload, ...plans]
      localStorage.setItem('plans',JSON.stringify(updPlans.toReversed())); //should it save unfilled plans?
    }
    else if (method == 'PUT') {
      const { planId, planDetails } = payload;
      const idx = plans.findIndex(p => p.planId == planId);

      plans[idx] = {...plans[idx], planDetails}
      localStorage.setItem('plans',JSON.stringify(plans.toReversed()));
    }
    else if (method == 'DELETE') {
      const updPlans = plans.filter(plan => plan.planId !== payload.planId);
      localStorage.setItem('plans',JSON.stringify(updPlans.toReversed()));
    }

    const defaultPlans = (appTitle == 'Wallet') ? TestPlans.plans : []
    const plansStr = localStorage.getItem('plans')
    const resjson = JSON.parse(plansStr) || defaultPlans
    setPlans(resjson.toReversed())
    setStatus('Success')
  }

  const fetchPlans = ( method, payload ) => 
    (appTitle == 'Plans' || appTitle == 'Wallet') 
    ? fetchPlansLocal( method, payload )
    : fetchPlansDB( method, payload )
    //: fetchPlansList( method, payload, appTitle, setPlans, setStatus )
    //: fetchPlansList( method, payload, appTitle, (list) => console.log(list), (status) => console.log(status) )

    useEffect(() => {
      if (status.startsWith("error")) {
        if (confirm(`An unexpected error has occurred - press OK to reload.\n"${status}"`)) {
          window.location.reload(); // Refresh the page after the alert is closed
        }
      }
   }, [status]);   

  useEffect(()=>{
    setStatus('loading');
    fetchPlans('GET')
    }, [appTitle]);

  const addPlan = (payload) => {
    fetchPlans('POST', payload)
    setSelectedCardIdx(0)
  }
  //can't update link or name
  const updatePlan = (planId) => (planDetails) => {
    const payload = {planId, planDetails}
    fetchPlans('PUT', payload)
  };

  const deletePlan = (planId) => {
    if (confirm(`Permanently delete planId #${planId}?`)) {
      
      fetchPlans('DELETE', {planId})
      setSelectedCardIdx(-1)
    }
  };

  const toolbarStyle = {
    overscrollBehavior: 'contain', //https://dev.to/mpuckett/the-holy-grail-web-app-shell-with-header-and-footer-for-iphone-549j
    //overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    colorScheme: 'light dark',
    color: 'var(--text-color)',
    padding: '16px 0vw', //slightly less padding than native passbook
    fontSize: toolbarHeight,
    maxHeight: toolbarHeight,
  }

  const planTypes = stackOptions['Plan Types']
  const activePlanTypes = Object.keys(planTypes).filter(key => planTypes[key] === true);
  const plansFiltered = plans.filter((p) => activePlanTypes.includes(p.planType))
  if (plansFiltered.length==1 && selectedCardIdx != 0) {
    setSelectedCardIdx(0) //need X button when 1 plan, doesnt work on first load
  }
  const openPlan = plansFiltered[selectedCardIdx]

  return (
    <div style={{padding: '0vw 4.5vw'}} ref={parentRef}>
      <div style={toolbarStyle}>
        <PlansProfile plans={plans} title={appTitle} setTitle={setAppTitle} parentRef={parentRef}/>
      <div style={{display: 'flex'}}>
        {selectedCardIdx < 0 
        ? <MultiOptionsButton diameter={toolbarHeight} selOptions={stackOptions} changeHandler={setStackOptions}/>
        : <PlanColorButton diameter={toolbarHeight} plan={openPlan} plansUpdater={updatePlan}/>
        }
          <div style={{width: '16px'}}></div>
        {selectedCardIdx < 0 
        ? <PlanAdder diameter={toolbarHeight} status={status} submitHandler={addPlan}/>
        : <DeleteButton diameter={toolbarHeight} onClick={() => deletePlan(openPlan.planId)}/>
        }
      </div>
      </div>

       {plansFiltered.length === 0 && (
          <div style={toolbarStyle}>
            {status == 'Success' && 'Hit the plus button to add a plan!'}
            {status.startsWith("error") && 'No Plans Found'}
          </div>
        )
       }
       {
        status === 'Success' && plansFiltered.length > 0 && (
           (plansFiltered.length === 1)
             ? <PlanCard height={bodyHeightInPixels}
                 trip={{...plansFiltered[0], planUpdater: updatePlan(plansFiltered[0].planId) }}
                 viewOptions={stackOptions['View Options']}
               />
             : <CardStack 
                 height={bodyHeightInPixels} footerHeight={toolbarHeight} 
                 headerHeight={stackOptions['View Options']['Mini Header'] ? 56 : 112}
                 selectedIdx={selectedCardIdx} selectedUpdater={setSelectedCardIdx}
               >
                 {plansFiltered.map((plan, i) => (
                   <Card key={plan.planId}>
                     <PlanCard 
                       trip={{...plan, ...(selectedCardIdx == i ? {planUpdater: updatePlan(plan.planId)} : {}) }}
                       height={bodyHeightInPixels} 
                       viewOptions={{...stackOptions['View Options']}}
                     />
                   </Card>
                 ))}
               </CardStack>
        )
       }

       {window.navigator.standalone && selectedCardIdx < 0 && <BlurBar height={toolbarHeight}/>}
     </div>
     );
}