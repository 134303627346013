import { useState } from 'react';
import { useForm } from "react-hook-form";
import { createSearchParams } from "react-router-dom";
import { ProfileModal, PageModal } from './PageModal';
import Button from "@kiwicom/orbit-components/lib/Button";
import { SelectNewButton } from './components/ImgButton';
import {ListAdder} from './ListAdder'
import {ResAdder} from './ResAdder'
import {FlightSearchAdder} from './FlightSearchAdder';
//import {EventAdder} from './EventAdder'

export const PlansProfile = ( props ) => {
  const [isOpen, setIsOpen] = useState(false);

  const changeTitle = () => {
    const inputPrompt = prompt("..... Who goes there?", 'G')
    if (inputPrompt) {
      props.setTitle(inputPrompt)
      setIsOpen(false)
    }
  }

  return (
    <>
    <b onClick={() => setIsOpen(true)}> {props.title} </b>
    <ProfileModal {...props} isOpen={isOpen} setIsOpen={setIsOpen} changeTitle={changeTitle} />
    </>
)}

const hydrateSearchPlan = ( data ) => {
  const planName = `${data.cityCodeFrom} → ${data.cityCodeTo}`
  const link = data.deep_link || '/search?' + createSearchParams(data).toString() 
  const img = process.env.PUBLIC_URL + '/plane.png'
  //const segId = `${segment.route[0].airline}${segment.route[0].flight_no}` //id needs utc_departure for uniqueness across days
  return {planName, link, img, ...data}
}

const hydrateResPlan = ( data ) => {
  const planName = data.name
  const start_date = data.res_date
  const link = '/res?' + createSearchParams(data).toString() 
  const img = 'https://i.giphy.com/TJssvTF16urPfizSVy.webp'
  return {planName, link, start_date, img, ...data}
}

const hydratePlacePlan = ( data ) => {
  const planName = data.name
  const link = data.resy_url
  const img = data.preview_image_url
  return {planName, link, img, ...data}
}

export const hydratePlan = ( planType, data ) => {
  const hydrators = {
    'search': hydrateSearchPlan,
    'res': hydrateResPlan,
    'place': hydratePlacePlan,
    'flightResult': hydrateSearchPlan,
  }

  if (!hydrators[planType]) throw new Error(`Unknown plan type: ${planType}`);

  const {link, planName, ...planDetails} = hydrators[planType](data);
  if (link == null || planName == null) throw new Error('Link and Name must not be null');

  return { planType, link, planName, planDetails }
}

export const PlanAdder = ( {submitHandler, diameter, status} ) => {
  const [showPlusModal, setShowPlusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planType, setPlanType] = useState('search'); //hardcoding default for now

  const { register, watch, control, handleSubmit, setValue } = useForm();
  const submitPayload = (data) => {
    setIsLoading(true)
    submitHandler(hydratePlan(planType, data))
    setIsLoading(false)
    setShowPlusModal(false)
  }

  const adderForms = {
    'list': <ListAdder register={register} watch={watch} setValue={setValue}/>,
    'search': <FlightSearchAdder register={register} control={control} setValue={setValue}/>,
    'res': <ResAdder register={register} watch={watch} setValue={setValue}/>,
    //'event': <EventAdder register={register} watch={watch}/>,
  }  

  const addlStyles = (status == 'loading') 
                   ? {animation: 'spin infinite 3000ms linear'} 
                   : {}

  return (
    <>
    <SelectNewButton 
      diameter={diameter}
      addlStyles={addlStyles}
      selOptions={{'options': Object.keys(adderForms)}}
      placeholder="What type of plan?"
      changeHandler={(val) => {
            setPlanType(val)
            setShowPlusModal(true)
          }}
    />
    <PageModal isOpen={showPlusModal} setIsOpen={setShowPlusModal} title='Add a Plan'>
      <form noValidate onSubmit={handleSubmit(submitPayload) }>
      { adderForms[planType] }
      <div style={{height: '24px'}}></div>
      <Button fullWidth={true} loading={isLoading} submit={true}>
      Submit
      </Button>
      </form>
    </PageModal>
    </>
  );
};